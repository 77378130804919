import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DropdownViewSelector = ({ onChange, cssClass }) => {
  const [view, setView] = useState('list');
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleViewChange = (selectedViewStyle) => {
    setView(selectedViewStyle);
    setIsOpen(false);
    onChange(selectedViewStyle);
  };

  return (
    <div className={`dropdown-view-selector ${cssClass}`}>
      <button type="button" className="dropdown-view-selector__button" onClick={toggleMenu} aria-expanded={isOpen}>
        <i className={`fas ${view === 'list' ? 'fa-list' : 'fa-th-large'}`} />
      </button>
      {isOpen && (
        <ul className="dropdown-view-selector__menu">
          <li className="dropdown-view-selector__item">
            <button
              type="button"
              className={`dropdown-view-selector__button-option ${view === 'list' ? 'dropdown-view-selector__button-option--active' : ''}`}
              onClick={() => handleViewChange('list')}
            >
              <i className="fas fa-list dropdown-view-selector__icon" />
            </button>
          </li>
          <li className="dropdown-view-selector__item">
            <button
              type="button"
              className={`dropdown-view-selector__button-option ${view === 'grid' ? 'dropdown-view-selector__button-option--active' : ''}`}
              onClick={() => handleViewChange('grid')}
            >
              <i className="fas fa-th-large dropdown-view-selector__icon" />
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

DropdownViewSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  cssClass: PropTypes.string,
};

DropdownViewSelector.defaultProps = {
  cssClass: '',
};

export default DropdownViewSelector;
