/**
 * Define the initial state for the app
 */

const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';

const initialState = {
  user: {
    error: false,
    errorMessage: '',
    successMessage: '',
    token,
    data: user,
  },
  camera: {
    cameraId: 0,
    image: {},
    date: '',
    project: {},
  },
  comparator: {
    cameraId: 0,
    firstImage: {},
    lastImage: {},
  },
  dates: {
    max: '',
    min: '',
  },
  shared: {
    loading: false,
  },
  projects: {
    list: [],
  },
};

export default initialState;
