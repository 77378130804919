/**
 * This is a root reducer, It controls all reducers
 */

import { combineReducers } from 'redux';
import userReducer from './userReducer';
import cameraReducer from './cameraReducer';
import comparatorReducer from './comparatorReducer';
import datesReducer from './datesReducer';
import sharedReducer from './sharedReducer';
import projectsReducer from './projectsReducer';

const rootReducer = combineReducers({
  user: userReducer,
  camera: cameraReducer,
  comparator: comparatorReducer,
  dates: datesReducer,
  shared: sharedReducer,
  projects: projectsReducer,
});

export default rootReducer;
