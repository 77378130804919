import {
  UPDATE_LOADING,
} from '../actions/types';
import initialState from './initialState';

export default function (state = initialState.shared, payload) {
  switch (payload.type) {
    case UPDATE_LOADING:
      return {
        ...state,
        loading: payload.state,
      };
    default:
      return state;
  }
}
