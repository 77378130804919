import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import * as api from '../../api/api';
import Logo from '../../assets/images/Logo-fotomanager.png';
import DropdownViewSelector from '../atoms/DropdownViewSelector';

/**
 * Load Actions
 */
import { setProjectsAction, setBase64ImgAction } from '../../actions/projectsActions';

const { REACT_APP_API_URL } = process.env;

/**
 * Module to format dates
 */
const format = require('dateformat');

class Projects extends Component {
  constructor() {
    super();
    this.state = {
      cameras: [],
      redirect: false,
      selectedProject: 0,
      loadingCameras: false,
      loadingProjects: false,
      mobileMenuOpen: false,
      projectsViewStyle: 'list',
    };
  }

  componentDidMount = () => {
    const {
      token, history, user, setProjects, projects,
    } = this.props;

    if (projects.length === 0) {
      const endPoint = user.role_id === 1 ? '/api/cameras/all' : '/api/cameras/assigned';
      this.setState({ loadingProjects: true });

      api.get(endPoint, { token })
        .then(
          (reply) => {
            if (!reply.error) {
              const formattedProjects = [];
              reply.cameras.forEach((camera) => {
                const project = formattedProjects.find(p => p.id === camera.project_id);
                if (project) {
                  project.cameras.push(camera);
                } else {
                  formattedProjects.push({ ...camera.project, cameras: [camera] });
                }
              });
              setProjects(formattedProjects.sort((a, b) => a.name.localeCompare(b.name)));
              this.showCameras(formattedProjects[0].id);
            } else {
              NotificationManager.error(reply.message);
              if (reply.close_session) {
                setTimeout(() => {
                  this.setState({ redirect: true });
                  history.push('/login');
                }, 5000);
              }
            }
            this.setState({ loadingProjects: false });
          },
          (error) => {
            if (error.message !== 'cancelado') {
              NotificationManager.error('Hubo un error consultando las cámaras.');
            }
            this.setState({ loadingProjects: false });
          },
        );
    } else {
      this.showCameras(projects[0].id);
    }
  }

  componentWillUnmount() {
    api.cancelRequest();
  }

  componentDidUpdate = () => {
    const { redirect } = this.state;
    const { updateApp } = this.props;
    if (redirect) {
      updateApp('login');
    }
  }

  showCameras = async (projectId) => {
    this.setState({ loadingCameras: true, cameras: [] });
    const { mobileMenuOpen } = this.state;
    const { projects, setBase64Img } = this.props;

    if (mobileMenuOpen) this.setState({ mobileMenuOpen: false });
    const selectedProject = projects.find(project => project.id === projectId);

    if (selectedProject) {
      const clonedProject = JSON.parse(JSON.stringify(selectedProject));
      const updatedCameras = await Promise.all(clonedProject.cameras.map(async (camera) => {
        const { base64Img } = camera;
        let base64Data = base64Img;
        if (!base64Img) {
          base64Data = await this.getCameraImage(camera.lastImage);
        }
        return { ...camera, base64Img: base64Data };
      }));

      const updatedProjecst = [
        ...projects.filter(project => project.id !== clonedProject.id),
        {
          ...clonedProject,
          cameras: updatedCameras,
        },
      ].sort((a, b) => a.name.localeCompare(b.name));

      setBase64Img(updatedProjecst);
      this.setState({ cameras: updatedCameras, selectedProject: projectId });
    }
    this.setState({ loadingCameras: false });
  }

  goToCamera = (cameraId) => {
    const { history, updateApp } = this.props;
    history.push(`/camera/${cameraId}`);
    updateApp('camera');
  }

  getCameraImage = async (image) => {
    const { token } = this.props;
    const imgUrl = `${REACT_APP_API_URL}/api${image.smartphone_file}?token=${token}`;
    const imageData = await fetch(imgUrl);
    const base64Image = await imageData.text();
    return `data:image/png;base64,${base64Image}`;
  }

  menuHandler = (status) => {
    this.setState({ mobileMenuOpen: status });
  }

  handleViewChange = (selectedView) => {
    this.setState({ projectsViewStyle: selectedView });
  }

  logout = async () => {
    const {
      token,
      history,
    } = this.props;

    await api.post('/api/user/logout', { token })
      .then(
        (reply) => {
          if (!reply.error) {
            this.setState({ redirect: true });
            history.push('/login');
          } else {
            NotificationManager.error(reply.message);
          }
        },
        (error) => {
          if (error.message !== 'cancelado') {
            NotificationManager.error('Hubo un error cerrando la sesión.');
          }
        },
      );
  };

  render() {
    const {
      cameras,
      selectedProject,
      loadingCameras,
      loadingProjects,
      mobileMenuOpen,
      projectsViewStyle,
    } = this.state;

    const { projects } = this.props;

    return (
      <>
        <img src={Logo} alt="" className="projects__mobile-logo" />
        <DropdownViewSelector
          onChange={selectedViewStyle => this.handleViewChange(selectedViewStyle)}
          cssClass="projects__dropdown-view-selector--mobile"
        />
        <button
          className="projects__sidebar__menu-button"
          onClick={() => this.menuHandler(true)}
          type="button"
        >
          <i className="fas fa-bars" />
        </button>
        <div className={`projects__sidebar ${mobileMenuOpen ? 'projects__sidebar--open' : ''}`}>
          <button
            type="button"
            className="projects__sidebar__close-button"
            onClick={() => this.menuHandler(false)}
          >
            <i className="far fa-times-circle" />
          </button>
          <div className="projects__sidebar__header">
            <img src={Logo} alt="" />
            <DropdownViewSelector
              onChange={selectedViewStyle => this.handleViewChange(selectedViewStyle)}
              cssClass="projects__dropdown-view-selector--desktop"
            />
          </div>
          <h2 className="projects__title">Proyectos</h2>
          <div>
            {loadingProjects && (<div>Cargando proyectos...</div>)}
            {projects.map(project => (
              <div key={project.id} className={`projects__project-item ${selectedProject === project.id ? 'projects__project-item--selected' : ''}`}>
                <button
                  type="button"
                  onClick={() => this.showCameras(project.id)}
                >
                  <i className="fas fa-building" />
                  {project.name}
                </button>
              </div>
            ))}
            <div className="projects__close-session-button">
              <button type="button" onClick={this.logout}>
                <i className="fas fa-arrow-right" />
                Cerrar Sesión
              </button>
            </div>
          </div>
        </div>
        <div className="project__content">
          <div className="projects__cameras-container">
            {loadingCameras && (<div className="projects__cameras-loading">Cargando cámaras...</div>)}
            {cameras.map(camera => (
              <div
                key={camera.id}
                className={`project__camera ${projectsViewStyle === 'grid' ? 'project__camera--grid' : ''}`}
              >
                <button
                  type="button"
                  onClick={() => this.goToCamera(camera.id)}
                >
                  <div>
                    <img alt={camera.name} src={camera.base64Img} />
                  </div>
                </button>
                <div className="project__title">
                  <span>{camera.name}</span>
                  <span>{ camera.lastImage?.created_at ? format(new Date(camera.lastImage?.created_at), 'dd mmm yyyy | h:MM:TT') : null }</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <NotificationContainer />
      </>
    );
  }
}

Projects.propTypes = {
  history: PropTypes.objectOf(PropTypes.shape).isRequired,
  token: PropTypes.string.isRequired,
  updateApp: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.shape).isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  setProjects: PropTypes.func.isRequired,
  setBase64Img: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  token: state.user.token,
  user: state.user.data,
  projects: state.projects.list,
});

const mapDispatchToProps = dispatch => ({
  setProjects: bindActionCreators(setProjectsAction, dispatch),
  setBase64Img: bindActionCreators(setBase64ImgAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Projects);
