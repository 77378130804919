import {
  UPDATE_LOADING,
} from './types';

export const updateLoadingAction = state => ({
  type: UPDATE_LOADING,
  state,
});

export default updateLoadingAction;
