import {
  SET_PROJECTS,
  SET_BASE64IMG,
} from '../actions/types';
import initialState from './initialState';

export default function (state = initialState.projects, payload) {
  switch (payload.type) {
    case SET_PROJECTS:
      return {
        ...state,
        list: payload.state,
      };
    case SET_BASE64IMG:
      return {
        ...state,
        list: payload.state,
      };
    default:
      return state;
  }
}
