import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useMediaQuery } from 'react-responsive';
import * as api from '../../api/api';

/**
 * Load Actions
 */
import cameraActions from '../../actions/cameraActions';
import { updateLoadingAction } from '../../actions/sharedActions';

const Arrows = ({
  token,
  cameraId,
  date,
  updateCameraData,
  image,
  updateLoading,
  loading,
}) => {
  // Hooks
  const [redirect, setRedirect] = useState(false);
  const [showArrows, setShowArrows] = useState(true);

  // Mobiles validations
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const Mobile = ({ children }) => {
    const mobile = useMediaQuery({ maxWidth: 576 });
    return mobile ? children : null;
  };

  // Function to get the image of the next day and the prev day
  const imagePerDay = async (action) => {
    try {
      updateLoading(true);
      const imagesReply = await api.asyncGet(`/api/image/${action}`, { token, camera_id: cameraId, date });
      if (!imagesReply.error) {
        if (imagesReply.image) {
          const img = {
            ...imagesReply.image,
            created_at: imagesReply.image.created_at.replace(' ', 'T'),
          };
          updateCameraData(img);
        }
      } else {
        NotificationManager.error(imagesReply.message);
        if (imagesReply.close_session) {
          setTimeout(() => { setRedirect(true); }, 5000);
        }
      }
    } catch (error) {
      updateLoading(false);
      NotificationManager.error('Hubo consultando los datos de la cámara.');
    }
  };

  // Method to get the next and prev image
  const NextPrevImage = async (action) => {
    await api.asyncGet(`/api/image/${action}`, { token, last_image: image.id, camera_id: cameraId })
      .then(
        (reply) => {
          if (!reply.error) {
            if (reply.image) {
              const imgg = {
                ...reply.image,
                created_at: reply.image.created_at.replace(' ', 'T'),
              };
              updateCameraData(imgg);
            }
          } else {
            NotificationManager.error(reply.message);
            if (reply.close_session) {
              setTimeout(() => { this.setState({ redirect: true }); }, 5000);
            }
          }
        },
        () => {
          NotificationManager.error('Hubo un error con la conexión.');
        },
      );
  };

  // Function to render to login if token is expired
  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to="/login" />;
    }
    return false;
  };

  // Function to show and hide the arrows
  const toggleArrows = () => {
    setShowArrows(!showArrows);
  };

  return (
    <Fragment>
      <section className={`arrows ${(!showArrows && isMobile) ? 'arrows--hidden' : ''}`}>
        {renderRedirect()}
        <div className="arrows__buttons">
          <button
            type="button"
            className={`arrows__button ${loading ? 'arrows__button--loading' : ''}`}
            onClick={() => {
              if (!loading) {
                imagePerDay('prevDay');
              }
            }}
          >
            <i className="fas fa-angle-double-left" />
          </button>
          <button
            type="button"
            className={`arrows__button ${loading ? 'arrows__button--loading' : ''}`}
            onClick={() => {
              if (!loading) {
                imagePerDay('nextDay');
              }
            }}
          >
            <i className="fas fa-angle-double-right" />
          </button>
        </div>
        <div className="arrows__buttons">
          <button
            type="button"
            className={`arrows__button ${loading ? 'arrows__button--loading' : ''}`}
            onClick={() => {
              if (!loading) {
                NextPrevImage('prev');
              }
            }}
          >
            <i className="fas fa-angle-left" />
          </button>
          <button
            type="button"
            className={`arrows__button ${loading ? 'arrows__button--loading' : ''}`}
            onClick={() => {
              if (!loading) {
                NextPrevImage('next');
              }
            }}
          >
            <i className="fas fa-angle-right" />
          </button>
        </div>
        <NotificationContainer />
      </section>

      <Mobile>
        <button
          type="button"
          className="arrows__button-toggle"
          onClick={toggleArrows}
        >
          <i className="fas fa-arrows-alt-h" />
        </button>
      </Mobile>
    </Fragment>
  );
};

Arrows.propTypes = {
  token: PropTypes.string.isRequired,
  cameraId: PropTypes.number.isRequired,
  image: PropTypes.objectOf(PropTypes.shape).isRequired,
  date: PropTypes.string.isRequired,
  updateCameraData: PropTypes.func.isRequired,
  updateLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  token: state.user.token,
  image: state.camera.image,
  cameraId: state.camera.cameraId,
  date: state.camera.date,
  loading: state.shared.loading,
});

const mapDispatchToProps = dispatch => ({
  updateCameraData: image => dispatch(cameraActions(image)),
  updateLoading: state => dispatch(updateLoadingAction(state)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Arrows);
