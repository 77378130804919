import {
  SET_PROJECTS,
  SET_BASE64IMG,
} from './types';

export const setProjectsAction = state => ({
  type: SET_PROJECTS,
  state,
});

export const setBase64ImgAction = state => ({
  type: SET_BASE64IMG,
  state,
});
